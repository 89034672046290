import React, { useState, useRef, useEffect } from "react";
import ApplicationTestLiveConfig from "../../application_test_live_config";
import VigilusButton from "../../button/vigilus-button";
import FormModalCustom from "../../form_modal_custom";
import { ApplicationTestSchedule } from "../../application_test_schedule";
import ApplicationTestConfiguration from "../../application_test_configuration";
import CallFlowTesterNewTestFormStep1 from "./new-test-form-step-1";

const CallFlowTesterNewTestFlowContainer = (props) => {
  const liveConfigRef = useRef(null);
  const [step, setStep] = useState(0);

  // form to be held in state, then submitted at the end
  const [step1FormState, setStep1FormState] = useState(null);
  const [configFormState, setConfigFormState] = useState(null);
  const [scheduleFormState, setScheduleFormState] = useState(null);
  // end form state

  const getNextOrSubmitButtonText = () => {
    switch (step) {
      case 4:
        return "Save Schedule and Create test";

      default:
        return "Next";
    }
  };

  const handleSaveFormDataToStateAndNextStep = async (data) => {
    switch (step) {
      case 1: {
        setStep1FormState(data);
        setStep(3); // TODO skipping config for now
        return;
      }
      case 2: // live config/dialer
        setStep(3);
        return;

      case 3: // test case manual config
        setStep(4);
        return;

      case 4: // schedule
        setStep(5); // submitting
        handleSaveAll();
        return;

      default:
        return null;
    }
  };

  const handleCreateTest = async (data) => {
    const response = await $.ajax({
      method: "POST",
      url: "/application_tests.json",
      data,
      success: function (response) {
        console.log("created test response: ", response);
      },
      error: function (err) {
        console.error("error: ", err);
      },
    });
    return response.id;
  };

  const handleCreateConfig = async (data, testId) => {
    const formattedBody = {
      application_test_configuration: {
        test_case: { ...data },
      },
    };
    $.ajax({
      method: "POST",
      url: `/application_tests/${testId}/test_cases.json`,
      data: JSON.stringify(formattedBody),
      contentType: "application/json",
      success: function (response) {
        console.log("config post response: ", response);
      },
      error: function (err) {
        console.error("error: ", err);
      },
    });
  };

  const handleCreateSchedule = async (data, testId) => {
    const formattedBody = {
      application_test_schedule: {
        ...data,
        application_test_id: testId,
        starting_at: data.starting_at.format("HH:mm:ss"),
        time_ends_at: data.time_ends_at.format("HH:mm:ss"),
        time_starts_at: data.time_starts_at.format("HH:mm:ss"),
        all_day: data.allDay,
      },
    };

    $.ajax({
      method: "POST",
      url: `/application_test_schedules.json`,
      data: formattedBody,
      success: function (response) {
        console.log("created schedule response: ", response);
        location.replace(
          `/application_tests/${response.application_test_id}?search=#tab_schedules`
        );
      },
      error: function (err) {
        console.error("error: ", err);
      },
    });
  };

  const handleSaveAll = async () => {
    // TODO before creating the test, update the org alerting email addresses with what's in the form

    const testId = await handleCreateTest(step1FormState);

    handleCreateConfig(configFormState, testId);
    handleCreateSchedule(scheduleFormState, testId);
  };

  const getIsFormDisabled = () => {
    switch (step) {
      case 3:
        if (
          !configFormState ||
          !configFormState.steps ||
          !configFormState.steps.length
        ) {
          return true;
        }

        return configFormState.steps.some((step) => !step.match.length);

      default:
        return false;
    }
  };

  // generate new disabled prop for config any time the form state changes
  useEffect(() => {
    getIsFormDisabled();
  }, [configFormState]);

  const handlePreviousStep = () => {
    if (step === 3) {
      return setStep(1); // temp fix since we're skipping step 2 live config
    }
    return setStep(step - 1);
  };

  return (
    <>
      <div className="flex items-center">
        <VigilusButton onClick={() => setStep(1)}>
          + Add Test (new combined flow)
        </VigilusButton>
        <FormModalCustom
          formAction="POST" // POST || PUT
          formDisabled={getIsFormDisabled()}
          formTitle="New Call Flow Test"
          isOpen={step}
          submitButtonText={getNextOrSubmitButtonText()}
          currentStep={step}
          totalSteps={5}
          onPreviousStep={handlePreviousStep}
          onClose={() => setStep(0)}
          onSubmit={(data) => handleSaveFormDataToStateAndNextStep(data, step)}
          hidefooter={step === 5}
        >
          <>
            {step === 1 && (
              <CallFlowTesterNewTestFormStep1
                step1FormState={step1FormState}
                sendTo={props.organization.email_destination}
              />
            )}
            {step === 2 && ( // live config/dialer
              <ApplicationTestLiveConfig ref={liveConfigRef} {...props} />
            )}
            {step === 3 && (
              <>
                <ApplicationTestConfiguration // Create test case(s)
                  configFormState={configFormState}
                  prefillConfigName={step1FormState["application_test[name]"]}
                  onChangeFormState={(data) => setConfigFormState(data)}
                  {...props}
                />
              </>
            )}
            {step === 4 && (
              <ApplicationTestSchedule
                {...props}
                toPhoneNumber={""}
                scheduleFormState={scheduleFormState}
                onChangeFormState={(data) => setScheduleFormState(data)}
                simplifiedForm={true}
                isDemo={true}
              />
            )}
            {step === 5 && ( // submitting
              <div className="flex p-2">Saving your test...</div>
            )}
          </>
        </FormModalCustom>
      </div>
    </>
  );
};

export default CallFlowTesterNewTestFlowContainer;
