import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

const OrgSelectCombobox = ({ options, selected, authenticity_token }) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [selectedOption, setSelectedOption] = useState(selected);

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(input.toLowerCase())
  );

  const formRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  return (
    <form
      ref={formRef}
      title="Organization Select"
      action="/organizations/set_organization"
      method="post"
      onFocus={() => setOpen(true)}
      className="set-org-form"
    >
      <div className="relative w-full">
        <input
          value={selectedOption.id}
          className="hidden"
          {...register("organization_id")}
        />
        <input
          value={authenticity_token}
          className="hidden"
          {...register("authenticity_token")}
        />

        <input
          onChange={(e) => setInput(e.target.value)}
          defaultValue={selected.name}
          className="w-full flex items-center w-full border border-border rounded-md p-4"
        />
        <i
          onClick={() => setOpen(true)}
          className="fa fa-angle-down absolute right-4 top-6 cursor-pointer"
        ></i>
      </div>

      {open && (
        <div className="fixed mt-2 w-[210px] max-h-96 bg-gray-200 border border-border rounded-md overflow-y-auto flex flex-col items-start">
          {filteredOptions.length === 0 && (
            <div className="p-2 cursor-not-allowed text-gray-600">
              No options found
            </div>
          )}
          {filteredOptions.length > 0 &&
            filteredOptions.map((option) => (
              <button
                key={option.id}
                className="w-full group flex items-center gap-2 rounded-lg p-2 select-none data-[focus]:bg-white/10 border-none hover:bg-white"
                onClick={() => setSelectedOption(option)}
              >
                <div className="w-5">
                  {option.id === selected.id && (
                    <i className="fas fa-check"></i>
                  )}
                </div>
                {option.name}
              </button>
            ))}
        </div>
      )}
    </form>
  );
};
export default OrgSelectCombobox;
