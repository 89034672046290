import React from "react";

const CallFlowTesterSelfServeConfirm = ({}) => {
  return (
    <form className="flex flex-col gap-6 items-center w-full text-center">
      <div>
        Success! Check your email for your confirmation link to
        complete&nbsp;signup.
      </div>
    </form>
  );
};
export default CallFlowTesterSelfServeConfirm;
