import React from "react";
import StatusCardsGrid from "./status-cards-grid/status-cards-grid";
import PageLayout from "../../layouts/page-layout";
import CallFlowTesterContent from "./call-flow-tester-content";

const CallFlowTesterContainer = ({
  statusCounts,
  oneDayResults,
  applicationTests,
  recentResults,
  sevenDayResults,
  thirtyDayResults,
  lastRuns,
  readOnly,
  demoOnly,
  organization,
}) => {
  return (
    <PageLayout title="Application Tests">
      <StatusCardsGrid
        statusCounts={statusCounts}
        oneDayResults={oneDayResults}
        recentResults={recentResults}
      />

      <CallFlowTesterContent
        applicationTests={applicationTests}
        recentResults={recentResults}
        oneDayResults={oneDayResults}
        sevenDayResults={sevenDayResults}
        thirtyDayResults={thirtyDayResults}
        lastRuns={lastRuns}
        readOnly={readOnly}
        demoOnly={demoOnly}
        organization={organization}
      />
    </PageLayout>
  );
};

export default CallFlowTesterContainer;
