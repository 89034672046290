import React from "react";
import StatusCard from "./status-card/status-card";

const StatusCardsGrid = ({ statusCounts, recentResults }) => {
  const remappedCounts = [
    {
      icon: <i className="far fa-check-circle"></i>,
      count: statusCounts ? statusCounts["Passed"] : 0,
      status: "Passing",
    },
    {
      icon: <i className="fas fa-redo"></i>,
      count: recentResults && recentResults[3] ? recentResults[3].length : 0, // TODO why is this [3]?
      status: "Recently Run",
    },
    {
      icon: <i className="fas fa-exclamation-circle"></i>,
      count: statusCounts ? statusCounts["Failed"] : 0,
      status: "Failed",
    },
  ];
  return (
    <div className="grid grid-cols-3 gap-8">
      {remappedCounts.map((status, index) => (
        <StatusCard
          key={index}
          icon={status.icon}
          count={status.count}
          status={status.status}
        />
      ))}
    </div>
  );
};
export default StatusCardsGrid;
