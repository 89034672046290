// TODO rename this file
import React from "react";
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  TableEmpty,
} from "../table/table";
import FormModalButton from "../form_modal_button";
import ApplicationTestFormModal from "../application_test_form_modal";
import { EventDots } from "../event_dots";
import SimpleSearchBar from "../simple-search-bar/simple-search-bar";
import VigilusButton from "../button/vigilus-button";
import CallFlowTesterNewTestFlowContainer from "./new-test-flow/new-test-flow-container";

export default class CallFlowTesterContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      filteredTests: this.props.applicationTests,
      currentPage: 1,
      loading: false,
    };
    this.liveConfigRef = React.createRef();
  }

  onSearch(e) {
    let filteredTests = this.props.applicationTests.filter((test) => {
      let searchString = e.target.value.toLowerCase();
      return (
        test.name.toLowerCase().indexOf(searchString) > -1 ||
        test.installed_application?.name.toLowerCase().indexOf(searchString) >
          -1 ||
        (test.enabled ? "Enabled" : "Disabled")
          .toLowerCase()
          .indexOf(searchString) > -1 ||
        (test.enabled
          ? last_run_status(this.props.lastRuns[test.id])
          : "Disabled"
        )
          .toLowerCase()
          .indexOf(searchString) > -1
      );
    });

    this.setState({ search: e.target.value, filteredTests: filteredTests });
  }

  exportTestResults = () => {
    let csv_string =
      "Name,Enabled,24H %,24H Passed,24H Total,7D %,7D Passed,7D Total,30D %,30D Passed,30D Total,Last Run,Last Result,Failed Reason";

    let csvData = new Blob([csv_string], { type: "text/csv;charset=utf-8;" });
    let csvURL = window.URL.createObjectURL(csvData);
    let tempLink = document.createElement("a");
    tempLink.download = "test_results_" + new Date().toISOString() + ".csv";
    tempLink.href = csvURL;
    tempLink.click();
    tempLink.remove();
    window.URL.revokeObjectURL(csvURL);
  };

  render() {
    let isEmpty = this.state.filteredTests.length == 0;

    let testCount = this.state.filteredTests.length;
    let pageCount = Math.ceil(testCount / 10);
    let currentPage = this.state.currentPage;
    if (currentPage > pageCount) {
      currentPage = pageCount;
    }
    let pageStart = (this.state.currentPage - 1) * 10;
    let pageEnd = pageStart + 10;
    let pageTests = this.state.filteredTests.slice(pageStart, pageEnd);

    return (
      <div
        className="bg-white rounded-md p-8 flex flex-col gap-4"
        style={{ border: `1px solid #d2d6de` }}
      >
        <div className="flex flex-col gap-2 sm:flex-row sm:items-center justify-between">
          <SimpleSearchBar onChange={this.onSearch.bind(this)} />

          <div className="flex items-center gap-2">
            {!this.props.readOnly && (
              <>
                {this.props.demoOnly ? (
                  <CallFlowTesterNewTestFlowContainer
                    demoOnly={this.props.demoOnly}
                    organization={this.props.organization}
                  />
                ) : (
                  <FormModalButton
                    label="+ Add Test"
                    type={"Application Test"}
                    status={"New"}
                    action={`/application_tests`}
                    {...this.props}
                  >
                    <ApplicationTestFormModal {...this.props} />
                  </FormModalButton>
                )}
              </>
            )}
            <VigilusButton primary onClick={exportTestResults}>
              <i className="fas fa-file-export"></i>
              Export
            </VigilusButton>
          </div>
        </div>
        <Table sortOn={7} sortDir={"desc"} loading={this.state.loading}>
          <TableHead>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Application</TableHeadCell>
            <TableHeadCell>Enabled</TableHeadCell>
            <TableHeadCell sortable={false}>Recent Results</TableHeadCell>
            <TableHeadCell>24H</TableHeadCell>
            <TableHeadCell>7D</TableHeadCell>
            <TableHeadCell>30D</TableHeadCell>
            <TableHeadCell>Last Run</TableHeadCell>
            <TableHeadCell>Last Result</TableHeadCell>
            <TableHeadCell sortable={false}></TableHeadCell>
          </TableHead>

          <TableEmpty>
            <p>No tests created yet</p>
          </TableEmpty>

          {this.state.filteredTests.map((test, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  <a
                    href={`/application_tests/${test.id}?search=#tab_history`}
                    className="underline hover:underline"
                  >
                    {test.name}
                  </a>
                </TableCell>
                <TableCell>{test.installed_application?.name || ""}</TableCell>
                <TableCell>
                  <span
                    className={test_run_enabled_disabled_status_class(test)}
                  >
                    {test.enabled ? "Enabled" : "Disabled"}
                  </span>
                </TableCell>
                <TableCell>
                  <EventDots events={this.props.recentResults[test.id] || []} />
                </TableCell>
                <TableCell>
                  {(this.props.oneDayResults &&
                    this.props.oneDayResults[test.id]) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {(this.props.sevenDayResults &&
                    this.props.sevenDayResults[test.id]) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {(this.props.thirtyDayResults &&
                    this.props.thirtyDayResults[test.id]) ||
                    "-"}
                </TableCell>
                <TableCell
                  sortFn={
                    () =>
                      new Date(
                        this.props.lastRuns?.[test.id]?.updated_at || null
                      ) // null Date defaults to 1970 so it will be sorted last
                  }
                >
                  {this.props.lastRuns &&
                    last_run_timestamp_in_words(this.props.lastRuns[test.id])}
                </TableCell>
                <TableCell>
                  <span
                    className={last_run_table_list_status_class(
                      test,
                      this.props.lastRuns[test.id]
                    )}
                  >
                    {test.enabled
                      ? last_run_status(this.props.lastRuns[test.id])
                      : "Disabled"}
                  </span>
                </TableCell>
                <TableCell>
                  <div>
                    {this.props.readOnly ? (
                      <FormModalButton
                        id={`show-${test.id}-application-test-modal`}
                        type={"Application Test"}
                        status={"show"}
                        action={`/application_tests/${test.id}`}
                      >
                        <ApplicationTestFormModal model={test} status="show" />
                      </FormModalButton>
                    ) : (
                      <div className="flex items-center gap-2">
                        <FormModalButton
                          id={`edit-${test.id}-application-test-modal`}
                          type={"Application Test"}
                          status={"edit"}
                          action={`/application_tests/${test.id}`}
                          secondary={true}
                        >
                          <ApplicationTestFormModal
                            model={test}
                            status="edit"
                          />
                        </FormModalButton>

                        <a
                          id={`delete-${test.id}-application-test-modal`}
                          className="bg-transparent rounded-md p-2 h-full text-red-700 hover:text-red-700 active:text-red-700 focus:text-red-700 hover:bg-red-100 border border-[#b91c1c] text-lg"
                          data-confirm={`Are you sure you want to delete test '${test.name}'?`}
                          rel="nofollow"
                          data-method="delete"
                          href={`/application_tests/${test.id}`}
                        >
                          Delete
                        </a>
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </div>
    );
  }
}

function last_run_table_list_status_class(test, test_run) {
  let curr_status;
  if (!test.enabled) {
    curr_status = "disabled";
  } else {
    curr_status = test_run?.status_code || "";
  }
  return small_status_class(curr_status);
}

function test_run_enabled_disabled_status_class(test) {
  const test_status = test.enabled ? "good" : "";
  return small_status_class(test_status);
}

function last_run_status(test_run) {
  return test_run ? test_run.status_display : "Never Run";
}

function last_run_timestamp_in_words(test_run) {
  if (!test_run) {
    return "-";
  }
  return test_run.formatted_timestamp_in_words;
}

function small_status_class(code) {
  switch (code) {
    case "good":
      return "text-[75%] font-bold w-64 rounded-full py-2 px-4 text-white bg-status-tag-green";
    case "bad":
      return "text-[75%] font-bold w-64 rounded-full py-2 px-4 text-white bg-status-tag-red";
    case "pending":
      return "text-[75%] font-bold w-64 rounded-full py-2 px-4 text-white bg-status-tag-blue";
    case "disabled":
      return "text-[75%] font-bold w-64 rounded-full py-2 px-4 bg-status-tag-gray";
    default:
      return "text-[75%] font-bold w-64 rounded-full py-2 px-4 bg-status-tag-gray";
  }
}
