import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";

const FormModalCustom = (props) => {
  const [formDisabled, setFormDisabled] = useState(props.formDisabled);
  const [errorString, setErrorString] = useState(null);

  const handleClose = () => {
    setFormDisabled(false);
    setErrorString(null);
    props.onClose();
  };

  const handleDisable = () => setFormDisabled(true);

  const submitForm = (e) => {
    e.preventDefault();
    handleDisable();
    const formData = new FormData(e.target);

    // Convert FormData to a JSON object
    const jsonObject = Object.fromEntries(formData);

    props.onSubmit(jsonObject);

    setFormDisabled(false);
  };

  let putInput = "";
  if (props.formAction === "PUT") {
    putInput = <input type="hidden" name="_method" value="PUT" />;
  }

  // keep formDisabled in sync with props.formDisabled
  useEffect(() => {
    if (formDisabled !== props.formDisabled) {
      setFormDisabled(props.formDisabled);
    }
  }, [props.formDisabled]);

  return (
    <div>
      <Modal
        show={Boolean(props.isOpen)}
        onHide={handleClose}
        bsSize={props.modalSize || "large"}
        backdrop={props.backdrop || true}
      >
        <Modal.Header closeButton>
          <div className="flex flex-col gap-2">
            <Modal.Title className="text-4xl">{props.formTitle}</Modal.Title>
            {props.currentStep && props.totalSteps && (
              <div className="text-gray-500">
                step {props.currentStep} / {props.totalSteps}
              </div>
            )}
          </div>
        </Modal.Header>
        {errorString && <div className="form_modal_errors">{errorString}</div>}
        <form
          method={props.formAction}
          acceptCharset="UTF-8"
          onSubmit={submitForm}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={$('meta[name="csrf-token"]').attr("content")} // TODO move up to container where form will eventually be submitted
          />
          {putInput}
          <Modal.Body>{props.children}</Modal.Body>

          {!props.hidefooter && (
            <Modal.Footer className="flex items-center justify-between w-full before:content-[] after:content-[]">
              <button
                onClick={handleClose}
                className="py-3 px-6 text-xl rounded-md text-[#444] border border-[#ddd] flex items-center gap-2 text-center font-medium bg-[#f4f4f4] hover:bg-[#e7e7e7]"
              >
                Close
              </button>

              <div className="flex items-center gap-2">
                {props.onPreviousStep && props.currentStep > 1 && (
                  <button
                    type="button"
                    onClick={props.onPreviousStep}
                    className="py-3 px-6 text-xl rounded-md text-[#444] border border-[#ddd] flex items-center gap-2 text-center font-medium bg-[#f4f4f4] hover:bg-[#e7e7e7]"
                  >
                    Back
                  </button>
                )}

                <input
                  type="submit"
                  value={props.submitButtonText}
                  className={`py-3 px-6 text-xl rounded-md text-primary flex items-center gap-2 text-center font-medium text-white bg-primary border-none hover:bg-primary-hover ${
                    formDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100"
                  }`}
                  disabled={props.formDisabled || formDisabled}
                />
              </div>
            </Modal.Footer>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default FormModalCustom;

window.renderForm = function (settings, open) {
  let containerId =
    settings.type.toLowerCase().split(" ").join("-") + "-form-modal";
  let container = document.getElementById(containerId);

  ReactDOM.render(<FormModalCustom {...settings} open={open} />, container);
};

window.renderFormZ = function (
  modalType,
  modalAction,
  modalStatus,
  open,
  containerId,
  model,
  context
) {
  let container = document.getElementById(containerId);
  ReactDOM.render(
    <FormModalCustom
      modalType={modalType}
      status={modalStatus}
      open={open}
      action={modalAction}
      model={model}
      context={context}
    />,
    container
  );
};
